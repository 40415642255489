<template>
  <NavCo></NavCo>

<div class="bg-strong">
  <div class="container col-xxl-8 px-4 py-5 bg-strong">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
      <div class="col col-sm-12 col-lg-6">
        <img src="../assets/401.jpeg" class="d-block mx-lg-auto img-fluid rounded-circle" alt="SI" width="700" height="500" loading="lazy">
      </div>
      <div class="col-sm-12 col-lg-6">
        <h1 class="display-1 fw-bold lh-1.5 mb-3 text-lg-start text-sm-center  text-light">SAQIB</h1>
      </div>
    </div>
  </div>
</div>


  <!-- First Container -->
<div class="container-fluid bg-1 text-center min-vh-100">
  <div class="container">
  <h2 class="display-5 fw-bold pb-5" id="#myprofile">My Profile</h2>
  <img src="../assets/329.jpeg" class="img-responsive img-circle margin rounded-circle" style="display:inline" alt="Bird" width="350" height="350">
  <p class="lead fs-3">I am a hardworking individual who enjoys new challenges. I am positive in any given situation. I am an enthusiastic, self-motivated individual and always seek self-development opportunities. I am an honest and reliable individual. I work well under pressure and can work to tight deadlines. I work well in a team as well as on my own initiative. I have excellent organisational, communication, and interpersonal skills. I enjoy interacting with people. I enjoy socialising with friends and colleagues.  I have a great deal of experience within the IT industry. I am a Professional Member of the British Computing Society. IT is my passion. My forte is analysis and programming of systems. I enjoy learning and am always learning new technologies and implementing them in commercial situations. I have acquired a wealth of Board Room experience during my many years of Project Management in the I.T. Industry, Local Authorities, Logistics, National Health Service, Hospitality, Banking Sector and the Teaching/Training profession. Presentations and demonstrations of proposals for new systems is paramount and mandatory in any industry. I have often presented my systems to Managers and end-users and the process has become second nature to me and my work in general.</p> 
  </div>
</div>

<div class="container col-xxl-8 px-4 py-5">
  <div class="row flex-lg-row align-items-center g-5 py-5">

    <div class="col-lg-6">
      <h2 class="display-5 fw-bold text-body-emphasis lh-1 mb-3 text-start" id="#education">Education</h2>
    </div>

    <div class="col-10 col-sm-8 col-lg-6">
      <div class="text-start fs-5">
        <ul class="list-group list-group-flush">
          <li class="list-group-item mb-4"><span class="fw-semibold">2006</span><br />BSc (Hons) Computer Science / Business Economics</li>
          <li class="list-group-item mb-4"><span class="fw-semibold">2003</span><br />DipHe Social Studies</li>
          <li class="list-group-item mb-4"><span class="fw-semibold">2000</span><br />A-Level Computing, Mechanics (Mathematics), Physics</li>
          <li class="list-group-item"><span class="fw-semibold">1998</span><br />GCSEs: English, Mathematics, Science, Spanish, French, Geography, Electronics</li>
        </ul>
      </div>
    </div>

    <!-- <div class="col-lg-6">
      <h2 class="display-5 fw-bold text-body-emphasis lh-1 mb-3 text-start" id="#education">Education</h2>
    </div> -->

  </div>
</div>


<!-- Second Container -->
<div class="container col-xxl-8 px-4 py-5">
    <div class="row flex-lg-row align-items-center g-5 py-5">

      <div class="col-lg-6">
        <h2 class="display-5 fw-bold text-body-emphasis lh-1 mb-3 text-start" id="#training">Training</h2>
      </div>

      <div class="col-10 col-sm-8 col-lg-6">
      <div class="text-start fs-5">
        <ul class="list-group list-group-flush">
          <li class="list-group-item mb-4"><span class="fw-semibold">ILM Management Training</span><br /><p class="fs-5">Problem Solving, Planning Change, Decision Making, Coaching, Minimising Stress, Marketing, Communication, Budgeting, Understanding Customers, Supporting Individuals, Identifying Self-Development, Health & Safety</p></li>

          <li class="list-group-item"><span class="fw-semibold">ICT Training</span><br />ECDL / CLAIT / New CLAIT / CLAIT Plus</li>
        </ul>
      </div>
      </div>
      <!-- <div class="col-lg-6">
        <h2 class="display-5 fw-bold text-body-emphasis lh-1 mb-3 text-start" id="#training">Training</h2>
      </div> -->
    </div>
  </div>


<!-- Third Container (Grid) -->
<div class="container-fluid bg-7 text-center min-vh-100">

    <div class="container px-4 p-5" id="icon-grid">
    <h2 class="display-5 fw-bold pb-5 text-start text-dark text-body-emphasis" id="#techstack">Tech Stack</h2>


    <div class="row row-cols-1 row-cols-sm-2  row-cols-lg-2 g-4 pt-5 pb-3 text-start">
      <div class="col d-flex align-items-start">
        <svg class="bi text-body-secondary flex-shrink-0 me-3 fs-2" width="1.75em" height="1.75em"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#bootstrap"></use></svg>
        <div>
          <h3 class="fw-bold mb-0 fs-1 text-body-emphasis pb-1">Web Servers / Web Hosting</h3>
          <p class="fs-2">Apache Server, Nginx, Tomcat, IIS, cPanel, LAMP, LEMP, WAMP, XAMPP, MAMP, Digital Ocean, Cloudways, Zenhosting, Ionos, 1&1, GoDaddy, WPEngine</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <svg class="bi text-body-secondary flex-shrink-0 me-3 fs-2" width="1.75em" height="1.75em"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#speedometer2"></use></svg>
        <div>
          <h3 class="fw-bold mb-0 fs-1 text-body-emphasis pb-1">Web Tech</h3>
          <p class="fs-2">PHP, JavaScript, VueJS, Vuetify, Ajax/jQuery, NodeJS (NPM), NVM, Laravel, FilamentPHP, Wordpress, Perl/CGI, HTML, Bootstrap, Tailwind CSS, XML, XSLT, XSL, AlpineJS</p>
        </div>
      </div>

      <div class="col d-flex align-items-start">
        <svg class="bi text-body-secondary flex-shrink-0 me-3 fs-2" width="1.75em" height="1.75em"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#calendar3"></use></svg>
        <div>
          <h3 class="fw-bold mb-0 fs-1 text-body-emphasis pb-1">Dev Tools</h3>
          <p class="fs-2">VS Code, PHP Storm, Zend Studio, Xcode, MS Visual Studio, SSRS, SSIS, Postman, Fiddler API, Laravel Sail, Docker</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <svg class="bi text-body-secondary flex-shrink-0 me-3 fs-2" width="1.75em" height="1.75em"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#house"></use></svg>
        <div>
          <h3 class="fw-bold mb-0 fs-1 text-body-emphasis pb-1">Database Systems</h3>
          <p class="fs-2">MSSQL, tSQL, Sybase Advantage Database Server, Oracle, MySQL, MS Access / VBA</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <svg class="bi text-body-secondary flex-shrink-0 me-3 fs-2" width="1.75em" height="1.75em"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#cpu-fill"></use></svg>
        <div>
          <h3 class="fw-bold mb-0 fs-1 text-body-emphasis pb-1">OS</h3>
          <p class="fs-2">Windows, Linux, OSX</p>
        </div>
      </div>

      <div class="col d-flex align-items-start">
        <svg class="bi text-body-secondary flex-shrink-0 me-3 fs-2" width="1.75em" height="1.75em"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#toggles2"></use></svg>
        <div>
          <h3 class="fw-bold mb-0 fs-1 text-body-emphasis pb-1">Graphics</h3>
          <p class="fs-2">Photoshop, Dreamweaver, GIMP</p>
        </div>
      </div>
      <div class="col d-flex align-items-start mb-2">
        <svg class="bi text-body-secondary flex-shrink-0 me-3 fs-2" width="1.75em" height="1.75em"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#geo-fill"></use></svg>
        <div>
          <h3 class="fw-bold mb-0 fs-1 text-body-emphasis pb-1">Desktop Dev</h3>
          <p class="fs-2">MS Visual Basic, Delphi / Pascal, Java</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <svg class="bi text-body-secondary flex-shrink-0 me-3 fs-2" width="1.75em" height="1.75em"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#tools"></use></svg>
        <div>
          <h3 class="fw-bold mb-0 fs-1 text-body-emphasis pb-1">CI/CD</h3>
          <p class="fs-2">Source Tree, GitLab, Github, GIT, SVN, CVS</p>
        </div>
      </div>

    </div>

  </div>

</div>




<div class="container-fluid bg-11">
<div class="container px-4 py-5 bg-11  min-vh-100">
<h2 class="display-5 fw-bold pb-5 text-start text-dark text-body-emphasis" id="#experience">Experience</h2>

  <div class="card text-start bg-transparent border-0 job">
    <div class="card-header d-flex fw-semibold bg-transparent">
      <div class="col-6">2015-2021</div><div class="col-6 text-end">Eclipse Software Ltd</div>
    </div>
    <div class="card-body">
      <h3 class="card-title fw-bold">PHP Developer / WordPress Plugin Developer</h3>
      <p class="card-text">
        <ul>
          <li>Develop, deploy and maintain company WordPress website and company Online Learning Portal (Moodle VLE)</li>
          <li>Develop, Maintain, extend, test and deploy company WordPress Plugins that interacts with Eclipse Recruitment Manager. Rigorous PHP code analysis, debugging, multi-platform deployment & testing.</li>
          <li>Test, maintain, extend and support the Web Integration portal - Eclipse RESTful API.</li>
          <li>Provide Second/Third line Technical Support for WordPress Plugin and RESTful API for clients. Liaise with client developers.</li>
          <li>Train staff for first line support of web products, WordPress plugin and Eclipse API for clients.</li>
          <li>Develop, test, deploy, maintain and support internal companywide web systems. PHP7 compatibility, upgrades & bugfix.</li>
          <li>Technologies Hyper-V, Xampp, IIS, Apache, PHP5/7, JavaScript, jQuery, HTML, CSS, MySQLi, MSSQL, GIT, Postman, Telerik.</li>
          <li>Developed company android app for integration with company API and company recruitment software.</li>
        </ul>
      </p>
    </div>
  </div>





    <div class="card text-start bg-transparent border-0 job">
    <div class="card-header d-flex fw-semibold bg-transparent">
      <div class="col-6">2010-2015</div><div class="col-6 text-end">Hospitality Training Academy</div>
    </div>
    <div class="card-body">
      <h3 class="card-title fw-bold">PHP Developer / PHP Web Application Developer / IT Manager</h3>
      <p class="card-text">
        <ul>
          <li>Implementation of Moodle-based Virtual Learning Environment with integration to training provider's Management Information System (MIS)</li>
          <li>Start to end analysis, custom design, development, implementation, testing and maintenance of Moodle Virtual Learning Environment (VLE).</li>
          <li>Start to end development and implementation of (CRUD) PHP web app for MIS, data warehousing and reporting purposes.</li>
          <li>VLE resources and activities developed; fully optimised and tested for cross-browser/cross-platform deployment. Customised eLearning resource development, implementation and deployment for various skill/entry levels by encompassing reading, auditory, visual, and interactive resources.</li>
          <li>Developed/implemented custom blocks and plugins for MIS-specific usage; user information, learner episode, progress data, custom reporting.</li>
          <li>Implemented PHP web apps for admin support; collating data from MIS and third-party systems, reporting for management and ofsted visit.</li>
          <li>Technologies used PHP, JavaScript, jQuery, Ajax, XML, HTML, MySQL, Apache/IIS webserver, MS SQL Server 2005, Advantage Database Server, Oracle SQL Developer, MS Access, VBA, MS Excel, Zend Studio, SharePoint Designer, cPanel, scorm authoring tools, resource authoring apps.</li>
          <li>Maintenance of Academy Website via Drupal CMS. Maintenance of existing web apps handling day-to-day Academy operations.</li>
          <li>Procure/Install/Configure/Maintain Academy back office and Hotel systems/hardware/software i.e. Opera PMS, Resident Pro, NFS booking systems, SBS 2011, Exchange, shared drives, office lan and public wifi, call logger, mitel ip phone system, digilock keycard system, general guest, conference and staff IT support, cctv, maintenance/repair of all hardware (computers, laptops, various electronics) in conjunction with vendors, maintenance of epos system including tills, printers, interconnectivity, back office epos software maintenance and support, epos reporting.</li>
        </ul>
      </p>
    </div>
    </div>





    <div class="card text-start bg-transparent border-0 job">
    <div class="card-header d-flex fw-semibold bg-transparent">
      <div class="col-6">2008-2010</div><div class="col-6 text-end">NHS – East Lancs PCT</div>
    </div>
    <div class="card-body">
      <h3 class="card-title fw-bold">PHP Developer / Data Warehouse Developer</h3>
      <p class="card-text">
        <ul>
          <li>Start to end development of (CRUD) PHP web apps for various pct services e.g. Child Health, Community Matrons, District Nurses, Macmillan Nurses, OT, Podiatry, Pulmonary Rehab, Smoking Cessation, SALT, Treatment Rooms. Full analysis, design, implementation, testing and maintenance of Chronos, VCFSF, Cpoc, MedCare, Pris systems. Apps used to capture/manage/maintain patient episodes of care recording from entry referrals through to discharges and follow-up contacts; Monitoring PCT staff and corresponding HR for commissioning purposes.</li>
          <li>Start to end development of PHP web app producing KPI reports required by PCT Commissioning. Reports produced for various PCT services using PCT databases and other data sources i.e. CPAS data-loads, NACS, SUS.</li>
          <li>Maintenance/development of existing (CRUD) PHP apps, MS Access/Excel, .NET, VB and C# systems. Development technologies used: PHP5, MSSQL Server 2005/SSIS, XML, AJAX, JavaScript, jQuery, XML, HTML, IIS webserver, CVS, Zend Studio. Other duties include DB Administration, Maintenance & Management of over 100 DBs. New/Existing user creation/association, Database Restores, Backups, Replications, OLAPs.</li>
          <li>Project Management of each system/database developed; employing SSADM, arranging/conducting boardroom meetings/presentations, collating vital system specifications; produce a proposal and schedule start of development.</li>
        </ul>
      </p>
    </div>
    </div>







    <div class="card text-start bg-transparent border-0 job">
    <div class="card-header d-flex fw-semibold bg-transparent">
      <div class="col-6">2007-2008</div><div class="col-6 text-end">CourierCompare LTD</div>
    </div>
    <div class="card-body">
      <h3 class="card-title fw-bold">PHP Developer / IT Manager</h3>
      <p class="card-text">
        <ul>
          <li>Design/implement fully automated price-comparison web app with third-party carrier integration. Projected system cost £quarter-mil.</li>
          <li>Initiated development of (CRUD) PHP web app for Logistics company. Home collection/delivery price comparison system with a complete automated booking system with integration with DHL, BusinessPost, UPS, FastWay and other Logistical Giants as well as small independent carriers. Approved Collections submitted to CourierCompare drivers via wap-push and third-party carriers via FTP.</li>
          <li>Conducted full analysis/design of proposed system with team of developers; used API's of third-party carriers to implement webservice.</li>
          <li>Web app encompassed comprehensive price-comparison engine, extensive data cleansing/validation processes, built-in postcode verification, comprehensive internal database search engine, data warehousing and reporting capabilities for billing.</li>
          <li>Worked with a team of third-party carriers' developer teams (international) to aid their integration of their business systems with our service.</li>
          <li>Technologies used PHP5, JavaScript, Ajax, SharePoint 2007, MySQL, MS SQL SERVER 2005, Apache/IIS webserver, MS Excel, MS Access, VB and FTP.</li>
          <li>Procure/Install/Configure/Maintain hardware/software/servers/computers/laptops/lan/wifi/phone system/multiple office IT setup and support.</li>
        </ul>
      </p>
    </div>
    </div>




    <div class="card text-start bg-transparent border-0 job">
    <div class="card-header d-flex fw-semibold bg-transparent">
      <div class="col-6">2006-2008</div><div class="col-6 text-end">PayPerDrop LTD</div>
    </div>
    <div class="card-body">
      <h3 class="card-title fw-bold">PHP Developer / PHP Web Application Developer / IT Manager</h3>
      <p class="card-text">
        <ul>
          <li>Implemented an automated booking system which took production from 30 manual collections to 3000 automated collections daily; boasting a turnover of £7 million at the end of the first year.</li>
          <li>Start to end development of (CRUD) PHP web app (ship'n'drop) for eBay-based logistics enterprise. Customer Collection Requests from online form processed; cleansed, validated, verified and submitted via FTP to DHL's mainframe 'Electronic Collection Request'/ECR system. ECR feedback received via FTP and processed through web app and other third party applications.</li>
          <li>Conducted full analysis, design, implementation and testing with DHL system engineers of web app. Web app encompassed complete booking system, data cleansing and data validation, built-in postcode verification, full comprehensive internal database search engine, data mining and reporting capabilities for billing. Scrutiny and regular maintenance of ship'n'drop uncovered flaws in DHL's systems. </li>
          <li>Implemented online booking system to capture customer parcel/s collection/delivery information via eBay. User booking confirmation produced and delivered via email to all relevant parties; carbon-copy to customer.</li>
          <li>Customer Collection Request data thoroughly processed. Extensive data validation using automated PHP scripts and VBA. Cleansed data scrutinised further via MS Excel and MS Access. Collections marked approved collated for batch process and FTP submission for ECR.</li>
          <li>Internal databases, eBay and PayPal data sources used to produce marketing, finance reports for Managing Director, Sales, Accounts and various other depts. reports published though MS Access, MS SQL Server 2005 and Excel Charting.</li>
          <li>Technologies used: PHP5, JavaScript, Ajax, Apache/IIS, HTML, MySQL, SQL Server 2005, MS Access, MS SSIS, MS Excel, VB/VBA, FTP, eBay, PayPal.</li>
          <li>Procure / Install / Configure / Maintain hardware / software / servers / computers / laptops / lan / wifi / phone system /multiple office IT setup and support.</li>
        </ul>
      </p>
    </div>
    </div>


  </div>
</div>





<!-- Footer -->
<FooterCo></FooterCo>


</template>

<script>
import NavCo from '@/components/NavCo.vue';
import FooterCo from '@/components/FooterCo.vue';
export default {
  components:{NavCo, FooterCo},
    setup() {
    }

}
</script>

<style lang="scss">
body {
    font: 20px Montserrat, sans-serif;
    line-height: 1.8;
    color: #f5f6f7;
  }
  p {font-size: 16px;}
  .margin {margin-bottom: 45px;}
  .bg-strong {
    background-color: #526E61;
    background-image: var(--bs-gradient);
  } 
  .bg-1 { 
    /* background-color: #1abc9c; Green */
    background-color: #F2FFF9;
    color: #474e5d;
    background-image: var(--bs-gradient);
  }
  .bg-1a { 
    background-color: #1abc9c; /*Green*/
    /* background-color: #F2FFF9; */
    color: white;
    background-image: var(--bs-gradient);
  }
  .bg-2 { 
    background-color: #474e5d; /* Dark Blue */
    color: #ffffff;
  }
  .bg-3 { 
    background-color: #ffffff; /* White */
    color: #555555;
  }
  .bg-4 { 
    background-color: #2f2f2f; /* Black Gray */
    color: #fff;
  }
  .bg-5 {
    background-color: #F7CE45;
    color: #2d2d2d;
    background-image: var(--bs-gradient);
  }
  .bg-6 {
    background-color: #f8f8ff;
    color: #7f8c8d;
  }
  .bg-7 {
    /* background-color: #F2FFF9; */
    background-color: #c9eddc;
    color: #4c5b5c;
  }
  .bg-8 {
    background-color: #127d66;
    color: white;
    background-image: var(--bs-gradient);
  }
  .bg-9 {
    background-color: #ccc9a1;
    color: black;
    background-image: var(--bs-gradient);
  }
  .bg-10 {
    background-color: #f0ffce;
    color: black;
    background-image: var(--bs-gradient);
  }
  .bg-11 {
    background-color: #FFFAF0;
    color: black;
    background-image: var(--bs-gradient);
  }
  .bg-12 {
    background-color: #fdf0d5;
    color: black;
    background-image: var(--bs-gradient);
  }


  .container-fluid {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .feature-icon {
    width: 48px;
    height: 48px;
  }
  .navbar {
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    font-size: 12px;
    letter-spacing: 5px;
    background-color: #f8f8ff;
  }
  .navbar-nav  li a:hover {
    color: #1abc9c !important;
  }

.job .card-body ul {
  padding: 0px;
  font-size: 1.5rem;
}
.job .card-body li {
  padding-bottom: 30px;
  list-style: none;
}
.job .card-body h3 {
  padding-bottom: 20px;
  padding-top: 20px;
}

</style>