<template>
  <NavCo></NavCo>


  <div class="container-fluid bg-dark">

<div class="p-5 text-center bg-body-tertiary">
  <div class="container-fluid py-5">
    <h1 class="display-1">Portfolio</h1>
    <p class="col-lg-8 mx-auto lead">
      This page is a showcase of some of the tech I am familiar with.<br />
      Each project has been put together using various types of tech in order to demo their use case.<br />
      Also listed is the tech used within each web app.
    </p>
  </div>
</div>




<div class="container py-5">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">CV</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">The current site has been put together using Vue components and some frontend helpers provided by bootstrap. It showcases how vue components can be used to put together a simple static responsive site.</p>
      <p>Vue 3 | NodeJS - NPM | Bootstrap 5</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <a href="https://saqibofficial.com"><button type="button" class="btn btn-outline-secondary btn-lg px-4">CV</button></a>        
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://saqibofficial.com"><img src="../assets/img/cvthumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="cv site" width="700" height="900" loading="lazy"></a>
      </div>
    </div>
  </div>
</div>



<div class="container pb-5">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">Rick and Morty</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">This site has been put together using Laravel 10. The site uses the Rick and Morty API to demo Laravel's API capabiltites. 
        The site contains three different implementations of the API.
        <ul class="text-start">
          <li>The first implementation is static HTML pages put together using Laravel, and some frontend helpers classes provided by Bootstrap</li>
          <li>The second implementation is a Single Page Application (SPA) put together using Laravel Livewire and Vue Components</li>
          <li>The third implementation is a Single Page Application (SPA) put together using Laravel and Vue Components</li>
        </ul>
      </p>
      <p>Laravel | Livewire | Vue 3 | NodeJS - NPM | Bootstrap | Tailwind</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <a href="https://rm.saqibofficial.com"><button type="button" class="btn btn-outline-secondary btn-lg px-4">Rick and Morty</button></a>
        <a href="/files/rm.apk" download="rm.apk"><img src="../assets/img/logos/play.png" alt="download android app" width="48" height="48"></a>       
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://rm.saqibofficial.com" target="_blank"><img src="../assets/img/rmthumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="cv site" width="700" height="900" loading="lazy"></a>
      </div>
    </div>
  </div>
</div>




<div class="container">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">Patient Management System</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">This site has been put together using <code>Laravel 10</code>  and some frontend helpers provided by <code>TailwindCSS</code> and <code>AlpineJS</code>.
        This application demo's a Patient Management System. It has CRUD functionality. It enables an organisation to create, store and search for patient data.
      </p>
      <p>Laravel 10 | NodeJS - NPM | TailwindCSS | AlpineJS | FilamentPHP</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">PMS</button>
        <a href="/files/pms.apk" download="pms.apk"><img src="../assets/img/logos/play.png" alt="download android app" width="48" height="48"></a>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://pms.saqibofficial.com" target="_blank"><img src="../assets/img/pmsthumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="cv site" width="700" height="900" loading="lazy"></a>      </div>
    </div>
  </div>
</div>



<div class="container pb-5">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">Instaman</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">This site has been put together using Laravel 10. It uses some Tailwind along with Bootstrap. The site mimics the functionlity of Instagram.</p>
      <p>Laravel | NodeJS - NPM | Bootstrap | Tailwind</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">Instaman</button>
        <a href="/files/instaman.apk" download="instaman.apk"><img src="../assets/img/logos/play.png" alt="download android app" width="48" height="48"></a>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://instaman.saqibofficial.com" target="_blank"><img src="../assets/img/instathumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="cv site" width="700" height="900" loading="lazy"></a>      </div>
    </div>
  </div>
</div>



<div class="container pb-5">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">Jobs4u</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">This site has been put together using Laravel 11. It uses some Tailwind along . The site mimics the functionlity of a Job Board.</p>
      <p>Laravel  |  NodeJS - NPM  |  Tailwind</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">Jobs4u</button>
        <a href="/files/instaman.apk" download="instaman.apk"><img src="../assets/img/logos/play.png" alt="download android app" width="48" height="48"></a>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://jobby.saqibofficial.com" target="_blank"><img src="../assets/img/jobs4uthumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="jobs site" width="700" height="900" loading="lazy"></a>      </div>
      </div>
  </div>
</div>






<div class="container pb-5">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">Note 11</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">This site has been put together using Laravel 11. It uses some TailwindCSS. The site allows you to create and store personalised notes</p>
      <p>Laravel 11 | NodeJS - NPM | TailwindCSS</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">Note 11</button>
        <a href="/files/instaman.apk" download="instaman.apk"><img src="../assets/img/logos/play.png" alt="download android app" width="48" height="48"></a>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://noter.saqibofficial.com" target="_blank"><img src="../assets/img/noterthumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="cv site" width="700" height="900" loading="lazy"></a>      </div>
    </div>
  </div>
</div>



<div class="container pb-5">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">API Central</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">This site has been put together using Vue. The site consumes multiple APIs using <code>Fetch</code> and displays them using various suites.</p>
      <p>Vue | Vue-Router | Vuetify | NodeJS - NPM | Bootstrap | Bootstrap Icons</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">API Central</button>
        <a href="/files/instaman.apk" download="instaman.apk"><img src="../assets/img/logos/play.png" alt="download android app" width="48" height="48"></a>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://apicentral.saqibofficial.com" target="_blank"><img src="../assets/img/apicentralpolicev2thumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="API Cental Police v2 site" width="700" height="900" loading="lazy"></a>      </div>
    </div>
  </div>
</div>




<div class="container">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">Rick and Morty</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">This site has been put together using Vue 3 and some frontend helpers provided by Bootstrap. The site uses the Rick and Morty API endpoints. The API is consumed via the <code>Fetch API</code></p>
      <p>Vue 3 | NodeJS - NPM | Bootstrap | SCSS</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">R&M Vue</button>
        <a href="/files/rmv.apk" download="rmv.apk"><img src="../assets/img/logos/play.png" alt="download android app" width="48" height="48"></a>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://phpstack-1057195-3823593.cloudwaysapps.com/" target="_blank"><img src="../assets/img/rmvuethumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="cv site" width="700" height="900" loading="lazy"></a>      </div>
    </div>
  </div>
</div>









  </div>
  <FooterCo></FooterCo>
</template>

<script>
import NavCo from '@/components/NavCo.vue'
import FooterCo from '@/components/FooterCo.vue';
export default {
  components: { NavCo, FooterCo },
  // methods: {
  //   downloadapk() {
  //     const link = document.createElement("a");
  //     link.href = '/public/app-release.apk';
  //     link.setAttribute("download", "app-release.apk");
  //     console.log(link);
  //     link.click();
  //   }
  // }
}
</script>

<style>

</style>